<template>
  <div class="message" :class="classNames">
    <span
      v-if="displayIcon"
      class="icon"
    >
        <icon :name="iconName"/>
    </span>
    <span
      v-if="displayCustom"
      class="custom"
    >
      {{ custom }}
    </span>

    <h1>{{ title }}</h1>
    <slot/>
  </div>
</template>

<script>
  import Icon from '@/shared/resources/components/Icon.vue';

  export default {
    components: {Icon},
    props: {
      title: String,
      custom: String,
      type: {
        type: String,
        default: 'info',
      },
    },

    data() {
      return {
        icons: {
          info: 'info',
          success: 'check_circle',
          warning: 'warning_amber',
          error: 'error_outline',
        },
      };
    },

    computed: {
      classNames() {
        return {
          ['message-' + this.type]: true,
        };
      },

      displayIcon() {
        return !this.custom;
      },

      displayCustom() {
        return !!this.custom;
      },

      iconName() {
        return this.icons[this.type];
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~vuetify/src/styles/main.sass';

  .message {
    text-align: center;
    max-width: 35rem;
    margin: auto;

    &-info {
      .icon {
        color: $info;
      }
    }

    &-success {
      .icon {
        color: $success;
      }
    }

    &-warning {
      .icon {
        color: $warning;
      }
    }

    &-error {
      .icon {
        color: $error;
      }
    }

    .icon {
      display: block;
      margin-bottom: 1rem;

      i {
        font-size: 10rem;
      }
    }

    .custom {
      color: $accent;
      font-size: 10rem;
      font-weight: 900;
      opacity: 0.5;
    }

    h1 {
      margin-bottom: 2rem;
      font-size: 2.5rem
    }
  }
</style>
